import React from "react";

function WelcomeBanner() {
  return (
    <div className="relative brand-bg-color-200 p-4 sm:p-6 rounded-sm overflow-hidden mb-8">
      {/* Content */}
      <div className="relative">
        <h1 className="text-2xl md:text-3xl brand-text-color-800 font-bold mb-1">Welcome back!</h1>
        <p>Please use the sidebar to navigate</p>
      </div>
    </div>
  );
}

export default WelcomeBanner;
