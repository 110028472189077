import React, { useEffect, useState } from "react";
import { doc, getDoc, serverTimestamp, updateDoc } from "firebase/firestore";
import { useNavigate, useParams } from "react-router-dom";

import Header from "../../partials/Header";
import { MdOutlineArrowBackIosNew } from "react-icons/md";
import Sidebar from "../../partials/Sidebar";
import Spinner from "../../components/Spinner";
import { db } from "../../db/firebase.config";
import { toast } from "react-toastify";

function EditUsers() {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  //const dispatch = useDispatch();
  const navigate = useNavigate();

  const { getUid } = useParams();
  const [editName] = useState("users");
  //const [fetch, setFetch] = useState(null);

  const [isLoading, setIsLoading] = useState(true);

  //Form
  const [formData, setFormData] = useState({});

  const [activeSelect, setActiveSelect] = useState(null);
  const [adminSelect, setAdminSelect] = useState(null);
  const [editorSelect, setEditorSelect] = useState(null);

  useEffect(() => {
    getFetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getFetch = async () => {
    const docRef = doc(db, editName, getUid);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      console.log(docSnap.data());
      //setFetch(docSnap.data());
      setFormData(docSnap.data());
      setActiveSelect(docSnap.data().active);
      setEditorSelect(docSnap.data().isEditor);
      setAdminSelect(docSnap.data().isAdmin);
    }

    setIsLoading(false);
  };

  const onSubmit = (e) => {
    e.preventDefault();

    const formCopy = { ...formData };

    formCopy.active = JSON.parse(activeSelect);
    formCopy.isEditor = JSON.parse(editorSelect);
    formCopy.isAdmin = JSON.parse(adminSelect);

    console.log(formCopy);
    edit(formCopy);
  };

  // DB CALL
  const edit = async (data) => {
    setIsLoading(true);
    try {
      const { uid } = data;
      data.updated = serverTimestamp();
      const docRef = doc(db, editName, uid);
      await updateDoc(docRef, data);
      toast.success("Updated");
    } catch (error) {
      toast.error("Could not update");
    }
    setIsLoading(false);
  };

  return (
    <div className="flex h-screen overflow-hidden">
      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden bg-white">
        {/*  Site header */}
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

        <main>
          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
            {/* Page header */}
            <div className="sm:flex sm:justify-between sm:items-center mb-8">
              {/* Left: Title */}
              <div className="mb-4 sm:mb-0">
                <h1 className="text-2xl md:text-3xl brand-text-color-800 font-bold">Edit user</h1>
              </div>
            </div>
            {isLoading ? (
              <Spinner />
            ) : (
              <div className="border-t brand-border-color-200">
                <>
                  <div className="grow">
                    <form onSubmit={onSubmit}>
                      {/* Panel body */}
                      <div className="p-6 space-y-6">
                        <h2 className="text-2xl brand-text-color-800 font-bold mb-5">Account: {formData.email}</h2>
                        {/* Name */}
                        <section>
                          <h2 className="text-xl leading-snug brand-text-color-800 font-bold mb-1">Profile</h2>
                          <div className="sm:flex sm:items-center space-y-4 sm:space-y-0 sm:space-x-4 mt-5">
                            <div className="sm:w-1/6">
                              <label className="block text-sm font-medium mb-1" htmlFor="name">
                                First Name
                              </label>
                              {formData.firstName}
                              {/* <input id="name" className="form-input w-full" type="text" name="name" value={name} onChange={onChange} required /> */}
                            </div>
                            <div className="sm:w-1/6">
                              <label className="block text-sm font-medium mb-1" htmlFor="name">
                                Last Name
                              </label>
                              {formData.lastName}
                              {/* <input id="name" className="form-input w-full" type="text" name="name" value={name} onChange={onChange} required /> */}
                            </div>
                          </div>
                        </section>

                        {/* Active */}
                        <section>
                          <div className="sm:w-1/3">
                            <label className="block text-sm font-medium mb-1" htmlFor="active">
                              Active
                            </label>
                            <select id="active" className="form-select" value={activeSelect} onChange={(e) => setActiveSelect(e.target.value)}>
                              <option value="true">True</option>
                              <option value="false">False</option>
                            </select>
                          </div>
                        </section>

                        {/* Editor */}
                        <section>
                          <div className="sm:w-1/3">
                            <label className="block text-sm font-medium mb-1" htmlFor="active">
                              Editor
                            </label>
                            <select id="active" className="form-select" value={editorSelect} onChange={(e) => setEditorSelect(e.target.value)}>
                              <option value="true">True</option>
                              <option value="false">False</option>
                            </select>
                          </div>
                        </section>

                        {/* Admin */}
                        <section>
                          <div className="sm:w-1/3">
                            <label className="block text-sm font-medium mb-1" htmlFor="active">
                              Admin
                            </label>
                            <select id="active" className="form-select" value={adminSelect} onChange={(e) => setAdminSelect(e.target.value)}>
                              <option value="true">True</option>
                              <option value="false">False</option>
                            </select>
                          </div>
                        </section>
                      </div>

                      {/* Panel footer */}
                      <footer>
                        <div className="flex justify-between px-6 py-5 border-t brand-border-color-200">
                          <div className="flex self-end">
                            {/* <button
                              onClick={() => navigate("/" + editName + "/" + editName + "/")}
                              className="btn brand-btn-color brand-btn-color-hover brand-btn-color-text ml-3"
                            >
                              <MdOutlineArrowBackIosNew className="mr-2" />
                              Back
                            </button> */}
                          </div>
                          <div className="flex self-start">
                            <button className="btn brand-btn-color brand-btn-color-hover brand-btn-color-text ml-3">Save Changes</button>
                          </div>
                        </div>
                      </footer>
                    </form>
                  </div>
                </>
              </div>
            )}
          </div>
        </main>
      </div>
    </div>
  );
}

export default EditUsers;
