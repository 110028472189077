import "react-toastify/dist/ReactToastify.css";
import "./css/style.scss";

import React, { useEffect, useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { doc, onSnapshot } from "firebase/firestore";

import Account from "./pages/settings/Account";
import AddProduct from "./pages/products/AddProduct";
import Changelog from "./pages/info/Changelog";
import EditProduct from "./pages/products/EditProduct";
import EditUser from "./pages/users/EditUser";
import En from "./pages/languages/English";
import Forgot from "./pages/ForgotPassword";
import Home from "./pages/Home";
import PageNotFound from "./pages/PageNotFound";
import PrivateRoute from "./components/PrivateRoute";
import Products from "./pages/products/Products";
import Se from "./pages/languages/Swedish";
import SearchProduct from "./pages/products/SearchProduct";
import SearchUser from "./pages/users/SearchUser";
import Signin from "./pages/Signin";
// import Signup from "./pages/Signup";
import Spinner from "./components/Spinner";
import { ToastContainer } from "react-toastify";
import Users from "./pages/users/Users";
import Version from "./pages/Version";
import { db } from "./db/firebase.config";

function App() {
  //App active
  const [active, setActive] = useState(false);

  onSnapshot(doc(db, "app", "status"), { includeMetadataChanges: true }, (doc) => {
    setActive(doc.data().active);
  });

  const location = useLocation();

  useEffect(() => {
    document.querySelector("html").style.scrollBehavior = "auto";
    window.scroll({ top: 0 });
    document.querySelector("html").style.scrollBehavior = "";
  }, [location.pathname]); // triggered on route change

  if (active) {
    return (
      <>
        <Routes>
          <Route path="/signin" element={<Signin />} />
          {/* <Route path="/signup" element={<Signup />} /> */}
          <Route path="/forgot" element={<Forgot />} />

          <Route path="/" element={<PrivateRoute />}>
            <Route path="/" element={<Home />} />
          </Route>

          <Route path="/settings/account" element={<PrivateRoute />}>
            <Route path="/settings/account" element={<Account />} />
          </Route>

          <Route path="/settings/account" element={<PrivateRoute />}>
            <Route path="/settings/account" element={<Account />} />
          </Route>

          <Route path="/info/changelog" element={<PrivateRoute />}>
            <Route path="/info/changelog" element={<Changelog />} />
          </Route>

          <Route path="/users/users" element={<PrivateRoute />}>
            <Route path="/users/users" element={<Users />} />
          </Route>
          <Route path="/users/edit-user/:getUid" element={<PrivateRoute />}>
            <Route path="/users/edit-user/:getUid" element={<EditUser />} />
          </Route>
          <Route path="/users/search" element={<PrivateRoute />}>
            <Route path="/users/search" element={<SearchUser />} />
          </Route>

          <Route path="/products/products" element={<PrivateRoute />}>
            <Route path="/products/products" element={<Products />} />
          </Route>
          <Route path="/products/edit-product/:getUid" element={<PrivateRoute />}>
            <Route path="/products/edit-product/:getUid" element={<EditProduct />} />
          </Route>
          <Route path="/products/add-product/" element={<PrivateRoute />}>
            <Route path="/products/add-product/" element={<AddProduct />} />
          </Route>
          <Route path="/products/search" element={<PrivateRoute />}>
            <Route path="/products/search" element={<SearchProduct />} />
          </Route>

          <Route path="/languages/english" element={<PrivateRoute />}>
            <Route path="/languages/english" element={<En />} />
          </Route>
          <Route path="/languages/swedish" element={<PrivateRoute />}>
            <Route path="/languages/swedish" element={<Se />} />
          </Route>

          <Route path="/version" element={<Version />} />

          <Route path="*" element={<PageNotFound />} />
        </Routes>
        <ToastContainer />
      </>
    );
  } else return <Spinner />;
}

export default App;
