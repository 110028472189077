import { getFirestore } from "firebase/firestore";
import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyAZ_pVPNkjIZw-OViaiWdLpOfRMRoMFgHA",
  authDomain: "charmth-eu.firebaseapp.com",
  databaseURL: "https://charmth-eu-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "charmth-eu",
  storageBucket: "charmth-eu.appspot.com",
  messagingSenderId: "762141078677",
  appId: "1:762141078677:web:703ee0b870e4691fbd579c",
  measurementId: "G-T29N18FMPQ",
};

// Initialize Firebase
initializeApp(firebaseConfig);
export const db = getFirestore();
