import React, { useState } from "react";

import Header from "../../partials/Header";
// import PaginationClassic from "../../components/PaginationClassic";
import Sidebar from "../../partials/Sidebar";

// import User01 from '../../images/user-32-01.jpg';
// import User02 from '../../images/user-32-02.jpg';
// import User07 from '../../images/user-32-07.jpg';

function Changelog() {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  return (
    <div className="flex h-screen overflow-hidden">
      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden bg-white">
        {/*  Site header */}
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

        <main>
          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
            {/* Page header */}
            <div className="sm:flex sm:justify-between sm:items-center mb-8">
              {/* Left: Title */}
              <div className="mb-4 sm:mb-0">
                <h1 className="text-2xl md:text-3xl brand-text-color-800 font-bold">Changelog</h1>
              </div>

              {/* Right: Actions */}
              <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2"></div>
            </div>

            <div className="border-t brand-border-color-200">
              <div className="max-w-3xl m-auto mt-8">
                {/* Posts */}
                <div className="xl:-translate-x-16">
                  {/* Post */}
                  {/* Post */}
                  <article className="pt-6">
                    <div className="xl:flex">
                      <div className="w-32 shrink-0">
                        <div className="text-xs font-semibold uppercase brand-text-color-400 xl:leading-8">May, 2022</div>
                      </div>
                      <div className="grow pb-6 border-b brand-border-color-200">
                        <header>
                          <a className="block mr-2 shrink-0" href="#1">
                            <h2 className="text-2xl brand-text-color-800 font-bold mb-3">Released version 0.1.1</h2>
                          </a>
                          <div className="flex flex-nowrap items-center space-x-2 mb-4">
                            <div className="flex items-center">
                              <p className="block text-sm font-semibold brand-text-color-900">Roger Prideaux</p>
                            </div>
                            {/* <div className="brand-text-color-400">·</div> */}
                            <div>
                              {/* <div className="text-xs inline-flex font-medium bg-emerald-100 text-emerald-600 rounded-full text-center px-2.5 py-1">
                                Product
                              </div> */}
                            </div>
                          </div>
                        </header>
                        <div className="space-y-3">
                          <p>Added:</p>
                          <p>Settings | Users | Products | Languages</p>
                        </div>
                      </div>
                    </div>
                  </article>

                  {/* Post */}
                  <article className="pt-6">
                    <div className="xl:flex">
                      <div className="w-32 shrink-0">
                        <div className="text-xs font-semibold uppercase brand-text-color-400 xl:leading-8">May, 2022</div>
                      </div>
                      <div className="grow pb-6 border-b brand-border-color-200">
                        <header>
                          <a className="block mr-2 shrink-0" href="#0">
                            <h2 className="text-2xl brand-text-color-800 font-bold mb-3">Released version 0.1.0</h2>
                          </a>
                          <div className="flex flex-nowrap items-center space-x-2 mb-4">
                            <div className="flex items-center">
                              <p className="block text-sm font-semibold brand-text-color-900">Roger Prideaux</p>
                            </div>
                            {/* <div className="brand-text-color-400">·</div> */}
                            <div>
                              {/* <div className="text-xs inline-flex font-medium bg-emerald-100 text-emerald-600 rounded-full text-center px-2.5 py-1">
                                Product
                              </div> */}
                            </div>
                          </div>
                        </header>
                        <div className="space-y-3">
                          <p>Initial architecture and framework with navigation, database, authentication and CSS.</p>
                        </div>
                      </div>
                    </div>
                  </article>
                </div>

                {/* Pagination */}
                {/* <div className="xl:pl-32 xl:-translate-x-16 mt-6">
                  <PaginationClassic />
                </div> */}
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}

export default Changelog;
