import { createUserWithEmailAndPassword, getAuth, sendPasswordResetEmail, signInWithEmailAndPassword, updateProfile } from "firebase/auth";
import { doc, getDoc, serverTimestamp, setDoc, updateDoc } from "firebase/firestore";

//import axios from "axios";
import { db } from "../../db/firebase.config";

// Register user
const register = async (userData) => {
  const auth = getAuth();
  const { firstName, lastName, email, password } = userData;
  const userCredentials = await createUserWithEmailAndPassword(auth, email, password);
  const fullName = firstName + " " + lastName;
  updateProfile(auth.currentUser, { displayName: fullName });

  const user = {
    firstName: firstName,
    lastName: lastName,
    email: userCredentials.user.email,
    uid: userCredentials.user.uid,
  };
  const userCopy = { ...user };
  userCopy.created = serverTimestamp();
  userCopy.active = true;

  if (user.uid) {
    localStorage.setItem("user", JSON.stringify(user));
  }
  await setDoc(doc(db, "users", user.uid), userCopy);
  console.log(user);
  return user;
};

// login user
const login = async (userData) => {
  const auth = getAuth();
  const { email, password } = userData;
  const userCredentials = await signInWithEmailAndPassword(auth, email, password);

  const docRef = doc(db, "users", userCredentials.user.uid);
  const docSnapshot = await getDoc(docRef);

  if (docSnapshot.exists() && docSnapshot.data().active) {
    console.log(docSnapshot.data());
    console.log(docSnapshot.data().uid);
  } else {
    throw new Error();
  }

  // Logout user if active = false
  // onSnapshot(doc(db, "users", userCredentials.user.uid), { includeMetadataChanges: true }, (doc) => {
  //   console.log(doc.data().active);

  //   if (doc.data() && !doc.data().active) {
  //     logout();
  //     //window.location.reload();
  //     console.log("FALSE: " + doc.data().active);
  //     //throw new Error();
  //   }
  // });

  const user = {
    firstName: docSnapshot.data().firstName,
    lastName: docSnapshot.data().lastName,
    email: docSnapshot.data().email,
    uid: docSnapshot.data().uid,
    active: docSnapshot.data().active,
    isAdmin: docSnapshot.data().isAdmin,
    isEditor: docSnapshot.data().isEditor,
  };

  if (user.uid && docSnapshot.data().isEditor) {
    localStorage.setItem("user", JSON.stringify(user));
  } else {
    throw new Error();
  }
  console.log(user);

  return user;
};

// Logout users
const logout = async () => {
  const auth = getAuth();
  auth.signOut(auth.currentUser);
  auth.currentUser = null;

  localStorage.removeItem("user");
};

// Update user
const updateUser = async (formData) => {
  const auth = getAuth();
  const { firstName, lastName } = formData;
  const fullName = firstName + " " + lastName;

  await updateProfile(auth.currentUser, { displayName: fullName });
  //Update email in Firebase
  //await updateEmail(auth.currentUser, email);
  //Update display name in Firestore
  const userRef = doc(db, "users", auth.currentUser.uid);
  await updateDoc(userRef, { firstName, lastName, updated: serverTimestamp() });
  //await updateDoc(userRef, formData);

  localStorage.setItem("user", JSON.stringify(formData));

  return formData;
};

// Forgot password
const forgotPassword = async (formData) => {
  const auth = getAuth();
  await sendPasswordResetEmail(auth, formData.email);
};

const authService = {
  register,
  logout,
  login,
  updateUser,
  forgotPassword,
};

export default authService;
