import React, { useEffect, useState } from "react";
import { collection, getDocs, limit, query, where } from "firebase/firestore";

import Header from "../../partials/Header";
import Sidebar from "../../partials/Sidebar";
import Spinner from "../../components/Spinner";
import { db } from "../../db/firebase.config";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

function SearchProduct() {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const navigate = useNavigate();

  const [editName] = useState("products");

  const [isLoading, setIsLoading] = useState(false);
  const [sku, setSku] = useState("");
  const [docId, setDocId] = useState(null);

  useEffect(() => {
    if (docId !== null) {
      navigate("/products/edit-product/" + docId);
    }
  }, [docId, navigate]);

  //Form
  const onSubmit = (e) => {
    e.preventDefault();
    console.log(sku);
    search();
  };

  // DB CALL
  const search = async () => {
    setIsLoading(true);
    const q = query(collection(db, editName), where("sku", "==", sku), limit(1));
    const querySnapshot = await getDocs(q);
    if (!querySnapshot.empty) {
      querySnapshot.forEach((doc) => {
        setDocId(doc.id);
      });
    } else {
      toast.error("No result for " + sku);
    }

    setIsLoading(false);
  };
  return (
    <div className="flex h-screen overflow-hidden">
      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden bg-white">
        {/*  Site header */}
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

        <main>
          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
            {/* Page header */}
            <div className="sm:flex sm:justify-between sm:items-center mb-8">
              {/* Left: Title */}
              <div className="mb-4 sm:mb-0">
                <h1 className="text-2xl md:text-3xl brand-text-color-800 font-bold">Search products</h1>
              </div>
            </div>
            {isLoading ? (
              <Spinner />
            ) : (
              <div className="border-t brand-border-color-200">
                <>
                  <div className="grow">
                    <form onSubmit={onSubmit}>
                      {/* Panel body */}
                      <div className="p-6 space-y-6">
                        {/* <h2 className="text-2xl brand-text-color-800 font-bold mb-5">Account: </h2> */}
                        {/* Name */}
                        <section>
                          {/* <h2 className="text-xl leading-snug brand-text-color-800 font-bold mb-1">Profile</h2> */}
                          <div className="sm:flex sm:items-center space-y-4 sm:space-y-0 sm:space-x-4 mt-5">
                            <div className="sm:w-1/4">
                              <label className="block text-sm font-medium mb-1" htmlFor="name">
                                Product SKU
                              </label>

                              <input
                                id="text"
                                className="form-input w-full"
                                type="text"
                                name="text"
                                value={sku}
                                onChange={(e) => setSku(e.target.value)}
                                required
                              />
                            </div>
                          </div>
                        </section>
                      </div>

                      {/* Panel footer */}
                      <footer>
                        <div className="flex justify-between px-6 py-5 border-t brand-border-color-200">
                          <div className="flex self-end"></div>
                          <div className="flex self-start">
                            <button className="btn brand-btn-color brand-btn-color-hover brand-btn-color-text ml-3">Search</button>
                          </div>
                        </div>
                      </footer>
                    </form>
                  </div>
                </>
              </div>
            )}
          </div>
        </main>
      </div>
    </div>
  );
}

export default SearchProduct;
