import React, { useState } from "react";
import { addDoc, collection } from "firebase/firestore";
import { getDownloadURL, getStorage, ref, uploadBytesResumable } from "firebase/storage";

import Header from "../../partials/Header";
import { MdOutlineArrowBackIosNew } from "react-icons/md";
import Sidebar from "../../partials/Sidebar";
import Spinner from "../../components/Spinner";
import { db } from "../../db/firebase.config";
import { getAuth } from "firebase/auth";
import noImg from "../../images/noImage.png";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

function EditUsers() {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const navigate = useNavigate();

  const auth = getAuth();

  const [isLoading, setIsLoading] = useState(false);
  const [editName] = useState("products");
  const [fetch] = useState(null);
  const [activeSelect, setActiveSelect] = useState("active");
  const [categorySelect, setCategorySelect] = useState("Cotton");
  const [mcSelect, setMcSelect] = useState("Cotton care");
  const [image1, setImage1] = useState(undefined);
  const [image2, setImage2] = useState(undefined);
  const [image3, setImage3] = useState(undefined);

  //Form
  const [formData, setFormData] = useState({
    sku: "",
    category: "",
    mc: "",
    name: "",
    details: "",
    price: "",
    primary: "",
    images: [],
    //imageUpload: [],
  });
  const { sku, name, details, price, primary } = formData;

  // DB CALL
  const edit = async (data) => {
    //const formSubmit = { ...data };
    try {
      //Store images in Firebase - START
      const storeImage = async (image) => {
        return new Promise((resolve, reject) => {
          const storage = getStorage();
          const fileName = `${auth.currentUser.uid}-${uuidv4()}-${image.name}`;

          const storageRef = ref(storage, "Uploads/" + fileName);

          const uploadTask = uploadBytesResumable(storageRef, image);

          uploadTask.on(
            "state_changed",
            (snapshot) => {
              const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              console.log("Upload is " + progress + "% done");
              switch (snapshot.state) {
                case "paused":
                  console.log("Upload is paused");
                  break;
                case "running":
                  console.log("Upload is running");
                  break;
                default:
                  break;
              }
            },
            (error) => {
              reject(error);
            },
            () => {
              // Handle successful uploads on complete
              // For instance, get the download URL: https://firebasestorage.googleapis.com/...
              getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                resolve(downloadURL);
              });
            }
          );
        });
      };

      if (data.image1 || data.image2 || data.image3) {
        const imgUrls = [];
        const imageUpload = [];
        if (data.image1) {
          const imgUrl1 = await storeImage(data.image1[0]);
          imgUrls[0] = imgUrl1;
          delete data.image1;
          imageUpload[0] = imgUrls[0];
          //imageUpload[0] = imgUrls[0] ? imgUrls[0] : fetch.images[0] ? fetch.images[0] : null;
        } else if (data.image2) {
          const imgUrl2 = await storeImage(data.image2[0]);
          imgUrls[1] = imgUrl2;
          delete data.image2;
          imageUpload[1] = imgUrls[1];
          //imageUpload[1] = imgUrls[1] ? imgUrls[1] : fetch.images[1] ? fetch.images[1] : null;
        }

        if (data.image3) {
          const imgUrl3 = await storeImage(data.image3[0]);
          imgUrls[2] = imgUrl3;
          delete data.image3;
          imageUpload[2] = imgUrls[2];
          //imageUpload[2] = imgUrls[2] ? imgUrls[2] : fetch.images[2] ? fetch.images[2] : null;
        }

        data.images = imageUpload;
        data.primary = imageUpload[0];
      }
      setImage1(undefined);
      setImage2(undefined);
      setImage3(undefined);

      console.log("Final form data");

      console.log(data);

      //const docRef = doc(db, editName, getUid);
      const docRef = collection(db, editName);
      //const res = await addDoc(docRef);
      const res = await addDoc(docRef, data);

      console.log("res.id");
      console.log(res.id);

      //await updateDoc(docRef, data);
      //await getFetch();
      //toast.success("Updated");
      navigate("/products/edit-product/" + res.id);
      toast.success("Added");
    } catch (error) {
      toast.error("Could not update");
      console.log(error);
    }

    setIsLoading(false);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);

    const formCopy = { ...formData };
    //Set active/disabled for status
    if (activeSelect === "active") {
      formCopy.active = true;
    } else formCopy.active = false;
    //Set Category -> Cotton or Linen
    if (categorySelect === "Cotton") {
      formCopy.category = "Cotton";
    } else if (categorySelect === "Linen") {
      formCopy.category = "Linen";
    }
    //Set MC -> Cotton or Linen
    if (mcSelect === "Cotton care") {
      formCopy.mc = "Cotton care";
    } else if (mcSelect === "Linen care") {
      formCopy.mc = "Linen care";
    }
    console.log(formCopy);
    if (!image1) {
      setIsLoading(false);
      toast.error("Must add an image");
      return;
    }
    edit(formCopy);
  };

  const onChange = (e) => {
    let boolean = null;
    console.log(e.target.id);

    if (e.target.value === "true") {
      boolean = true;
    }
    if (e.target.value === "false") {
      boolean = false;
    }

    // Files
    if (e.target.files) {
      setFormData((prevState) => ({
        ...prevState,
        [e.target.id]: e.target.files,
      }));
      if (e.target.id === "image1") setImage1(URL.createObjectURL(e.target.files[0]));
      if (e.target.id === "image2") setImage2(URL.createObjectURL(e.target.files[0]));
      if (e.target.id === "image3") setImage3(URL.createObjectURL(e.target.files[0]));
    }

    // Text/Booleans/Numbers
    if (!e.target.files) {
      setFormData((prevState) => ({
        ...prevState,
        [e.target.id]: boolean ?? e.target.value,
      }));
    }
  };

  return (
    <div className="flex h-screen overflow-hidden">
      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden bg-white">
        {/*  Site header */}
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

        <main>
          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
            {/* Page header */}
            <div className="sm:flex sm:justify-between sm:items-center mb-8">
              {/* Left: Title */}
              <div className="mb-4 sm:mb-0">
                <h1 className="text-2xl md:text-3xl brand-text-color-800 font-bold">Add product</h1>
              </div>
            </div>
            {isLoading ? (
              <Spinner />
            ) : (
              <>
                <div className="border-t brand-border-color-200">
                  <>
                    <div className="grow">
                      <form onSubmit={onSubmit}>
                        {/* Panel body */}
                        <div className="p-6 space-y-6">
                          <h2 className="text-2xl brand-text-color-800 font-bold mb-5">Product: {fetch ? fetch.sku : null}</h2>
                          {/* SKU, Category, Material care, Price, Active */}
                          <section>
                            {/* <h2 className="text-xl leading-snug brand-text-color-800 font-bold mb-1">Details</h2> */}
                            <div className="sm:flex sm:items-center space-y-4 sm:space-y-0 sm:space-x-4 mt-5">
                              <div className="sm:w-1/4">
                                <label className="block text-sm font-medium mb-1" htmlFor="name">
                                  SKU
                                </label>
                                <input id="sku" className="form-input w-full" type="text" name="sku" value={sku} onChange={onChange} required />
                              </div>
                              <div className="sm:w-1/6">
                                <label className="block text-sm font-medium mb-1" htmlFor="name">
                                  Category
                                </label>
                                <select id="category" className="form-select" value={categorySelect} onChange={(e) => setCategorySelect(e.target.value)}>
                                  <option value="Cotton">Cotton</option>
                                  <option value="Linen">Linen</option>
                                </select>
                              </div>
                              <div className="sm:w-1/6">
                                <label className="block text-sm font-medium mb-1" htmlFor="name">
                                  Material care
                                </label>
                                <select id="mc" className="form-select" value={mcSelect} onChange={(e) => setMcSelect(e.target.value)}>
                                  <option value="Cotton care">Cotton care</option>
                                  <option value="Linen care">Linen care</option>
                                </select>
                              </div>
                              <div className="sm:w-1/6">
                                <label className="block text-sm font-medium mb-1" htmlFor="name">
                                  Price
                                </label>
                                <input id="price" className="form-input w-full" type="text" name="price" value={price} onChange={onChange} required />
                              </div>
                              <div className="sm:w-1/6">
                                <label className="block text-sm font-medium mb-1" htmlFor="active">
                                  Status
                                </label>
                                <select id="active" className="form-select" value={activeSelect} onChange={(e) => setActiveSelect(e.target.value)}>
                                  <option value="active">Active</option>
                                  <option value="disabled">Disabled</option>
                                </select>
                              </div>
                            </div>
                          </section>

                          {/* Name, Details */}
                          <section>
                            <div className="sm:flex sm:items-center space-y-4 sm:space-y-0 sm:space-x-4 mt-5">
                              <div className="sm:w-1/4">
                                <label className="block text-sm font-medium mb-1" htmlFor="name">
                                  Name
                                </label>
                                <input id="name" className="form-input w-full" type="text" name="name" value={name} onChange={onChange} required />
                              </div>
                              <div className="sm:w-3/4">
                                <label className="block text-sm font-medium mb-1" htmlFor="name">
                                  Details
                                </label>
                                <input id="details" className="form-input w-full" type="text" name="details" value={details} onChange={onChange} required />
                              </div>
                            </div>
                          </section>

                          {/* Images */}
                          {fetch ? (
                            <section>
                              <div className="sm:flex sm:items-center space-y-4 sm:space-y-0 sm:space-x-4 mt-5">
                                <>
                                  <div className="sm:w-1/4">
                                    <label alt="" className="block text-sm font-medium mb-1" htmlFor="email">
                                      Image 1 [Primary image]
                                    </label>
                                    <img
                                      src={fetch.images[0] ? fetch.images[0] : noImg}
                                      alt=""
                                      width="100%"
                                      className="border-dotted border-2 brand-border-color-500"
                                    />
                                  </div>

                                  {/* <div className="sm:w-1/4">
                                  <label alt="" className="block text-sm font-medium mb-1" htmlFor="email">
                                    Image 2
                                  </label>
                                  <img
                                    src={fetch.images[1] || images[1] ? fetch.images[1] : noImg}
                                    alt=""
                                    width="100%"
                                    className="border-dotted border-2 brand-border-color-500"
                                  />
                                </div>

                                <div className="sm:w-1/4">
                                  <label alt="" className="block text-sm font-medium mb-1" htmlFor="email">
                                    Image 3
                                  </label>
                                  <img
                                    src={fetch.images[2] || images[2] ? fetch.images[2] : noImg}
                                    alt=""
                                    width="100%"
                                    className="border-dotted border-2 brand-border-color-500"
                                  />
                                </div> */}
                                </>

                                <div className="sm:w-1/4">
                                  <label className="block text-sm font-medium mb-1" htmlFor="email">
                                    Primary image
                                  </label>
                                  <img src={primary} alt="" width="100%" className="border-solid border-4 brand-border-color-500" />
                                </div>
                              </div>
                            </section>
                          ) : null}

                          {/* Image upload */}
                          <section>
                            <h2 className="text-xl leading-snug brand-text-color-800 font-bold mb-1">{fetch ? "Replace" : "Add"} images</h2>

                            <div className="sm:flex sm:items-center space-y-4 sm:space-y-0 sm:space-x-4 mt-5">
                              <>
                                <div className="sm:w-1/4">
                                  <label className="block text-sm font-medium mb-1" htmlFor="email">
                                    Image 1 [Primary image]
                                  </label>
                                  <input
                                    className="form-input w-full"
                                    type="file"
                                    id="image1"
                                    name="image1"
                                    onChange={onChange}
                                    max="1"
                                    accept=".jpg,.png,.jpeg"
                                    multiple
                                  />
                                  {image1 && <img src={image1} alt="" width="100%" className="border-dotted border-2 brand-border-color-500" />}
                                </div>
                              </>
                              {image1 || (fetch ? fetch.images[0] : null) ? (
                                <>
                                  <div className="sm:w-1/4">
                                    <label className="block text-sm font-medium mb-1" htmlFor="email">
                                      Image 2
                                    </label>
                                    <input
                                      className="form-input w-full"
                                      type="file"
                                      id="image2"
                                      name="image2"
                                      onChange={onChange}
                                      max="1"
                                      accept=".jpg,.png,.jpeg"
                                      multiple
                                    />
                                    {image2 && <img src={image2} alt="" width="100%" className="border-dotted border-2 brand-border-color-500" />}
                                  </div>
                                </>
                              ) : null}

                              {image2 || (fetch ? fetch.images[1] : null) ? (
                                <>
                                  <div className="sm:w-1/4">
                                    <label className="block text-sm font-medium mb-1" htmlFor="email">
                                      Image 3
                                    </label>
                                    <input
                                      className="form-input w-full"
                                      type="file"
                                      id="image3"
                                      name="image3"
                                      onChange={onChange}
                                      max="1"
                                      accept=".jpg,.png,.jpeg"
                                      multiple
                                    />
                                    {image3 && <img src={image3} alt="" width="100%" className="border-dotted border-2 brand-border-color-500" />}
                                  </div>
                                </>
                              ) : null}
                            </div>
                          </section>
                        </div>

                        {/* Panel footer */}
                        <footer>
                          <div className="flex justify-between px-6 py-5 border-t brand-border-color-200">
                            <div className="flex self-end">
                              <button
                                onClick={() => navigate("/" + editName + "/" + editName + "/")}
                                className="btn brand-btn-color brand-btn-color-hover brand-btn-color-text ml-3"
                              >
                                <MdOutlineArrowBackIosNew className="mr-2" />
                                Back
                              </button>
                            </div>
                            <div className="flex self-start">
                              <button className="btn brand-btn-color brand-btn-color-hover brand-btn-color-text ml-3">Save Changes</button>
                            </div>
                          </div>
                        </footer>
                      </form>
                    </div>
                  </>
                </div>
              </>
            )}
          </div>
        </main>
      </div>
    </div>
  );
}

export default EditUsers;
