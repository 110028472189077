// import Header from "../partials/Header";
import { Link } from "react-router-dom";
import React from "react";
// import Sidebar from "../partials/Sidebar";
import logo from "../images/LOGO-DARK.png";

// import { useSelector } from "react-redux";

//import NotFoundImage from '../../images/404-illustration.svg';

function PageNotFound() {
  // const [sidebarOpen, setSidebarOpen] = useState(false);
  // const { user } = useSelector((state) => state.auth);

  return (
    <>
      <div className="flex items-center justify-between h-16 px-4 sm:px-6 lg:px-8">
        {/* Logo */}
        <Link className="block" to="/">
          <img src={logo} alt="" width="50%" />
        </Link>
      </div>

      <div className="flex h-screen overflow-hidden">
        {/* Sidebar */}

        {/* Content area */}
        <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden bg-white">
          {/*  Site header */}

          <main>
            <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
              <div className="max-w-2xl m-auto mt-16">
                <div className="text-center px-4">
                  <div className="inline-flex mb-8">{/* <img src={NotFoundImage} width="176" height="176" alt="404 illustration" /> */}</div>
                  <h1 className="text-6xl font-bold brand-text-color-500">VERSION ERROR</h1>
                  <div className="mb-6 brand-text-color-500">App version and Live version do not match, please reload page or contact support.</div>
                  {/* <Link to={"/"} className="btn brand-btn-color brand-btn-color-hover brand-btn-color-text ml-3">
                    Back To Home
                  </Link> */}
                  <button onClick={() => window.location.reload()} className="btn brand-btn-color brand-btn-color-hover brand-btn-color-text ml-3">
                    Reload page
                  </button>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  );
}

export default PageNotFound;
