import { collection, doc, getDocs, limit, orderBy, query, serverTimestamp, updateDoc } from "firebase/firestore";

import { db } from "../../db/firebase.config";

//import { getAuth, updateProfile } from "firebase/auth";

// Get users
const getUsers = async () => {
  //Get reference
  const usersRef = collection(db, "users");

  //Create query
  //const q = query(usersRef, where("active", "==", true), orderBy("created", "desc"), limit(10));
  const q = query(usersRef, orderBy("created", "desc"), limit(2));

  //Execute query
  const querySnapshot = await getDocs(q);

  const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];

  const users = [];

  querySnapshot.forEach((doc) => {
    //console.log(doc.data());
    if (!doc.data().updated) {
      doc.data().updated = null;
    }
    if (!doc.data().created) {
      doc.data().created = null;
    }

    return users.push({
      uid: doc.id,
      data: {
        ...doc.data(),
        created: doc.data().created ? doc.data().created.toDate().toDateString() : null,
        // toString() = long
        // toDateString() = Day Mon XX XXXX
        // toLocaleString = DD/MM/YYYY xx:xx:xx
        updated: doc.data().updated ? doc.data().created.toDate().toDateString() : null,
      },
    });
  });
  console.log(users);
  return { users, lastVisible };
};

// Get users
const editUsers = async (user) => {
  const { uid } = user;
  user.updated = serverTimestamp();
  // const auth = getAuth();
  // updateProfile(auth.uid, { displayName: name });
  //console.log(uid);
  const docRef = doc(db, "users", uid);
  await updateDoc(docRef, user);
  //console.log(user);
};

const usersService = {
  getUsers,
  editUsers,
};

export default usersService;
