import { login, reset } from "../features/auth/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";

// import AuthDecoration from "../images/auth-decoration.png";
// import AuthImage from "../images/auth-image.jpg";
import { Link } from "react-router-dom";
import React from "react";
import Spinner from "../components/Spinner";
import logo from "../images/LOGO-DARK.png";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

function Signin() {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const { email, password } = formData;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { user, isLoading, isError, isSuccess, message } = useSelector((state) => state.auth);

  useEffect(() => {
    if (isError) {
      toast.error(message);
    }

    //Redirect when logged in
    if (isSuccess || user) {
      navigate("/");
    }

    dispatch(reset());
  }, [isError, isSuccess, user, message, navigate, dispatch]);

  const onChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const userData = {
      email,
      password,
    };
    dispatch(login(userData));
  };

  return (
    <main className="bg-white">
      <div className="relative md:flex">
        {/* Content */}
        <div className="md:w-1/2">
          <div className="min-h-screen h-full flex flex-col after:flex-1">
            {/* Header */}
            <div className="flex-1">
              <div className="flex items-center justify-between h-16 px-4 sm:px-6 lg:px-8">
                {/* Logo */}
                <Link className="block" to="/">
                  <img src={logo} alt="" width="50%" />
                </Link>
              </div>
            </div>
            {isLoading ? (
              <Spinner />
            ) : (
              <div className="max-w-sm mx-auto px-4 py-8">
                <h1 className="text-3xl brand-text-color-800 font-bold mb-6">Welcome back!</h1>
                {/* Form */}
                <form onSubmit={onSubmit}>
                  {" "}
                  <div className="space-y-4">
                    <div>
                      <label className="block text-sm font-medium mb-1" htmlFor="email">
                        Email Address
                      </label>
                      <input
                        id="email"
                        className="form-input w-full"
                        type="email"
                        //       type="email"
                        // className="form-control"
                        // id="email"
                        name="email"
                        value={email}
                        onChange={onChange}
                        placeholder="Please enter your email"
                        required
                      />
                    </div>
                    <div>
                      <label className="block text-sm font-medium mb-1" htmlFor="password">
                        Password
                      </label>
                      <input
                        id="password"
                        className="form-input w-full"
                        type="password"
                        autoComplete="on"
                        // type="password"
                        // className="form-control"
                        // id="password"
                        name="password"
                        value={password}
                        onChange={onChange}
                        placeholder="Please enter your password"
                        required
                      />
                    </div>
                  </div>
                  <div className="flex items-center justify-between mt-6">
                    <div className="mr-1">
                      <Link className="text-sm underline hover:no-underline" to="/forgot">
                        Forgot Password?
                      </Link>
                    </div>

                    <button className="btn brand-btn-color brand-btn-color-hover brand-btn-color-text ml-3">Sign In</button>
                  </div>
                </form>
                {/* Footer */}
                {/* <div className="pt-5 mt-6 border-t brand-border-color-200">
                  <div className="text-sm">
                    Don’t have an account?{" "}
                    <Link className="font-medium brand-text-color-500 underline hover:no-underline brand-text-color-600" to="/signup">
                      Sign Up
                    </Link>
                  </div> */}
                {/* Warning */}
                {/* <div className="mt-5">
                  <div className="bg-amber-100 text-amber-600 px-3 py-2 rounded">
                    <svg className="inline w-3 h-3 shrink-0 fill-current mr-2" viewBox="0 0 12 12">
                      <path d="M10.28 1.28L3.989 7.575 1.695 5.28A1 1 0 00.28 6.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 1.28z" />
                    </svg>
                    <span className="text-sm">To support you during the pandemic super pro features are free until March 31st.</span>
                  </div>
                </div> */}
                {/* </div> */}
              </div>
            )}
          </div>
        </div>

        {/* Image */}
        <div className="hidden md:block absolute top-0 bottom-0 right-0 md:w-1/2 brand-bg-color" aria-hidden="true">
          {/* <img className="object-cover object-center w-full h-full" src={AuthImage} width="760" height="1024" alt="Authentication" />
          <img className="absolute top-1/4 left-0 transform -translate-x-1/2 ml-8 hidden lg:block" src={AuthDecoration} width="218" height="224" alt="Authentication decoration" /> */}
        </div>
      </div>
    </main>
  );
}

export default Signin;
