import React, { useEffect, useState } from "react";
import { doc, getDoc, updateDoc } from "firebase/firestore";

import Header from "../../partials/Header";
import Sidebar from "../../partials/Sidebar";
import Spinner from "../../components/Spinner";
import { db } from "../../db/firebase.config";
//import en from "./en.js";
//import se from "./se.js";
import { toast } from "react-toastify";

// import { getUsers, reset } from "../../features/users/usersSlice";
// import { useDispatch, useSelector } from "react-redux";

function En() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [collName] = useState("languages");
  const [langName] = useState("en");

  const [lang, setLang] = useState(null);

  //Form
  const [formData, setFormData] = useState(null);

  useEffect(() => {
    getFetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const sortObject = (o) => {
  //   var sorted = {},
  //     key,
  //     a = [];

  //   for (key in o) {
  //     if (o.hasOwnProperty(key)) {
  //       a.push(key);
  //     }
  //   }

  //   a.sort();

  //   for (key = 0; key < a.length; key++) {
  //     if (typeof o[a[key]] === "object") {
  //       sorted[a[key]] = sortObject(o[a[key]]);
  //     } else {
  //       sorted[a[key]] = o[a[key]];
  //     }
  //   }
  //   return sorted;
  // };

  const getFetch = async () => {
    const docRef = doc(db, collName, langName);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      //const res = sortObject(docSnap.data());

      const data = docSnap.data();

      const res = Object.keys(data)
        .sort(function (a, b) {
          return a.toLowerCase().localeCompare(b.toLowerCase());
        })
        // eslint-disable-next-line no-sequences
        .reduce((r, k) => ((r[k] = data[k]), r), {});

      setFormData(res);
      setLang(res);

      setIsLoading(false);
    }
  };

  const getLang = () => {
    const res = [];
    var i = 0;
    for (var key in lang) {
      if (lang.hasOwnProperty(key)) {
        // res.push(
        //   <div key={i}>
        //     {key} {"->"} {en[key]}
        //   </div>
        // );

        res.push(
          <section key={i}>
            <div className="sm:flex sm:items-center space-y-4 sm:space-y-0 sm:space-x-4 mt-5">
              <div className="sm:w-full">
                <label className="block text-sm font-medium mb-1" htmlFor="name">
                  {key}
                </label>
                <input id={key} className="form-input w-full" type="text" name={key} value={formData[key]} onChange={onChange} required />
              </div>
            </div>
          </section>
        );
        //console.log(key + " -> " + en[key]);
        i++;
      }
    }

    return res;
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const formCopy = { ...formData };
    //await setDoc(doc(db, "languages", "se"), formCopy);

    try {
      const docRef = doc(db, "languages", langName);
      await updateDoc(docRef, formCopy);
      toast.success("Updated");
    } catch (error) {
      toast.error("Could not update");
      console.log(error);
    }

    //console.log(formCopy);
    //edit(formCopy);
    setIsLoading(false);
  };

  const onChange = (e) => {
    let boolean = null;
    //console.log(e.target.id);

    if (e.target.value === "true") {
      boolean = true;
    }
    if (e.target.value === "false") {
      boolean = false;
    }

    // Files
    if (e.target.files) {
    }

    // Text/Booleans/Numbers
    if (!e.target.files) {
      setFormData((prevState) => ({
        ...prevState,
        [e.target.id]: boolean ?? e.target.value,
      }));
    }
  };
  return (
    <div className="flex h-screen overflow-hidden">
      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden bg-white">
        {/*  Site header */}
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

        <main>
          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
            {/* Page header */}
            <div className="sm:flex sm:justify-between sm:items-center mb-8">
              {/* Left: Title */}
              <div className="mb-4 sm:mb-0">
                <h1 className="text-2xl md:text-3xl brand-text-color-800 font-bold">Language: English</h1>
              </div>
            </div>
            {isLoading ? (
              <Spinner />
            ) : (
              <div className="border-t brand-border-color-200">
                <>
                  <div className="grow">
                    <form onSubmit={onSubmit}>
                      {/* Panel body */}
                      <div className="p-6 space-y-6">{getLang()}</div>

                      {/* Panel footer */}
                      <footer>
                        <div className="flex justify-between px-6 py-5 border-t brand-border-color-200">
                          <div className="flex self-end">
                            {/* <button
                              onClick={() => navigate("/" + editName + "/" + editName + "/")}
                              className="btn brand-btn-color brand-btn-color-hover brand-btn-color-text ml-3"
                            >
                              <MdOutlineArrowBackIosNew className="mr-2" />
                              Back
                            </button> */}
                          </div>
                          <div className="flex self-start">
                            <button className="btn brand-btn-color brand-btn-color-hover brand-btn-color-text ml-3">Save Changes</button>
                          </div>
                        </div>
                      </footer>
                    </form>
                  </div>
                </>
              </div>
            )}
          </div>
        </main>
      </div>
    </div>
  );
}

export default En;
