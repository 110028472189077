import React, { useState } from "react";

import Header from "../partials/Header";
import Sidebar from "../partials/Sidebar";
import WelcomeBanner from "../partials/dashboard/WelcomeBanner";

function Home() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  return (
    <div className="flex h-screen overflow-hidden">
      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        {/*  Site header */}
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

        <main>
          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
            {/* Page content start */}
            {/* /* Welcome banner */}
            <WelcomeBanner />

            {/* <HeaderOld />
            <section className="heading">
              <h1>What do you need help with?</h1>
              <p>Please choose from an option below</p>
            </section> */}
            {/* <Link to="/new-ticket" className="btn btn-reverse btn-block">
              <FaQuestionCircle /> Create New Ticket
            </Link>
            <Link to="/tickets" className="btn btn-block">
              <FaTicketAlt /> View My Tickets
            </Link> */}
            {/* Page content end */}
          </div>
        </main>
      </div>
    </div>
  );
}

export default Home;
